import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/transport'
import { useLangContext } from '../../context/lang.context'
import { useFormContext } from '../../context/form.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import { sLinkTiles } from '../../style'
import LinkTile from '../../components/LinkTile'

const Transport = () => {
  const { lang } = useLangContext()
  const { setFormOpen, setFormType } = useFormContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/transport.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      transportSea: file(
        absolutePath: { regex: "/images/intros/transport-morski.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      transportAir: file(
        absolutePath: { regex: "/images/intros/transport-lotniczy.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      transportLand: file(
        absolutePath: { regex: "/images/intros/transport-ladowy.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      services: file(absolutePath: { regex: "/images/intros/uslugi.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Dowiedz się więcej',
      en: 'Learn more',
      ua: 'ДІЗНАТИСЯ БІЛЬШЕ ',
    },

    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Wycena transportu',
      en: 'Transport quote',
      ua: 'ЦІНА ТРАНСПОРТУВАННЯ',
    },
    action: () => {
      setFormType('QUOTE')
      setFormOpen(true)
    },
  }

  const links = [
    {
      slug: '/transport/morski/',
      title: {
        pl: 'Transport Morski',
        en: 'Sea Transport',
        ua: 'Морський транспорт',
      },
      subtitle: {
        pl: 'Usługi Sea & Air',
        en: 'Sea & Air Services',
        ua: 'ПОСЛУГИ SEA & AIR',
      },
      image: query.transportSea.childImageSharp,
    },
    {
      slug: '/transport/lotniczy/',
      title: {
        pl: 'Transport Lotniczy',
        en: 'Air Transport',
        ua: 'Повітряний транспорт',
      },
      subtitle: {
        pl: 'Usługi Sea & Air',
        en: 'Sea & Air Services',
        ua: 'ПОСЛУГИ SEA & AIR',
      },
      image: query.transportAir.childImageSharp,
    },
    {
      slug: '/transport/ladowy/',
      title: {
        pl: 'Transport Lądowy',
        en: 'Land Transport',
        ua: 'Наземний транспорт',
      },
      subtitle: {
        pl: 'Usługi Sea & Air',
        en: 'Sea & Air Services',
        ua: 'ПОСЛУГИ SEA & AIR',
      },
      image: query.transportLand.childImageSharp,
    },
    {
      slug: '/uslugi/',
      title: {
        pl: 'Pozostałe Usługi',
        en: 'Other Services',
        ua: 'Інші послуги',
      },
      subtitle: {
        pl: 'Usługi Sea & Air',
        en: 'Sea & Air Services',
        ua: 'ПОСЛУГИ SEA & AIR',
      },
      image: query.services.childImageSharp,
    },
  ]

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={query.image.childImageSharp}
        position='top'
      />
      <Main h={1} title={main.title[lang]} body={main.body[lang]}>
        <div css={sLinkTiles}>
          {links.map((item, id) => (
            <LinkTile key={id} data={item} />
          ))}
        </div>
      </Main>
    </Layout>
  )
}

export default Transport
