export const seo = {
  url: 'transport',
  title: {
    pl: 'Transport',
    en: 'Transport',
    ua: 'Транспорт',
  },
  desc: {
    pl: `Polski lider transportu morskiego, lotniczego i kolejowego. Sprawdź usługi transportowe Omida Sea And Air.`,
    en: `Polish leader in sea, air and rail transport. Check out Omida Sea And Air transport services.`,
    ua: `Польський лідер морського, повітряного та наземного транспорту. Перегляньте послуги морського та повітряного транспорту Omida.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'usługi',
    'spedycja',
    'transport',
    'logistyka',
  ],
}

export const intro = {
  title: {
    pl: 'Transport',
    en: 'Transport',
    ua: 'Транспорт',
  },
  desc: {
    pl: `Polski lider transportu morskiego, lotniczego i lądowego. Sprawdź usługi transportowe Omida Sea And Air.`,
    en: `Polish leader in sea, air and land transport. Check out Omida Sea And Air transport services.`,
    ua: `Польський лідер морського, повітряного та наземного транспорту. Перегляньте послуги морського та повітряного транспорту Omida.`,
  },
}

export const main = {
  title: {
    pl: 'Transport morski, lotniczy, lądowy. Sprawdź usługi Omida Sea And Air',
    en: 'Sea, Air, Land Transport. <br/>Check out Omida Sea And Air services',
    ua: 'Морський, повітряний і наземний транспорт. Ознайомтеся з послугами Omida Sea and Air',
  },
  body: {
    pl: `
    <p>
    Obsługujemy transport ładunków całokontenerowych i drobnicowych. Zajmujemy się także obsługą celną, dokumentacją i magazynowaniem.
    </p>
    <p>
    Obsługujemy przesyłki w relacji „door-to-door” – oznacza to, że zadbamy o Twój towar od momentu przekazania przez nadawcę do dostarczenia do drzwi odbiorcy.
    </p>
    <p>
    Współpracujemy z największymi liniami lotniczymi i zapewniamy profesjonalną obsługę transportu
    pomiędzy nadawcami, odbiorcami, a portami lotniczymi.
    </p>
    <p>
    Niezależnie od warunków pogodowych lub drogowych dostarczamy ładunek do wybranych miejsc w Europie i Azji, zapewniając pełną obsługę logistyczną.
    </p>
    `,
    en: `
    <p>
    We handle transport of full container loads and groupage loads. We also provide customs, warehousing and documentation services.
    </p>
    <p>
    We handle shipments in the "door-to-door" relationship - this means that we will take care of your goods from the moment they are handed over by the sender until they are delivered to the recipient's door.
    </p>
    <p>
    We work with the largest airlines and provide professional transport service between senders, recipients and airports.
    </p>
    <p>
    Regardless of weather or road conditions, we deliver cargo to selected places in Europe and Asia, providing full logistic service.
    </p>
    `,
    ua: `
    <p>
    Ми здійснюємо перевезення повних контейнерних вантажів і збірних вантажів. Ми також займаємося митними послугами, оформленням документації та складуванням.
    </p>
    <p>
    Ми обслуговуємо відправлення за принципом «від дверей до дверей» — це означає, що ми піклуватимемося про ваш товар від моменту його передачі відправником до доставки до дверей одержувача.
    </p>
    <p>
    Ми працюємо з найбільшими авіакомпаніями та надаємо професійні транспортні послуги між відправниками, одержувачами та аеропортами.
    </p>
    <p>
    Незалежно від погодних і дорожніх умов ми доставляємо вантажі в обрані місця Європи та Азії, надаючи повний логістичний сервіс.
    </p>
    `,
  },
}
